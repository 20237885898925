import instance from '../services/api.js';

class IdentityClientsProxy {
  static canselationTokens = [];

  static GetAll() {
    return instance.get('/api/client/all').catch((err) => {
      console.log(err);
    });
  }

  static GetClientInfo(id) {
    this.canselationTokens.forEach((x) => x.cancel());
    this.canselationTokens = [];
    const source = instance.CancelToken.source();
    this.canselationTokens.push(source);
    return instance
      .get(`api/client/${id}`, {
        cancelToken: source.token,
      })
      .catch((err) => {
        console.log(err);
      });
  }

  static SaveClient(client) {
    return instance.put('api/client', client).catch((err) => {
      console.log(err);
    });
  }

  static RemoveClient(id) {
    return instance.delete(`api/client/${id}`).catch((err) => {
      console.log(err);
    });
  }

  static CreateClient(client) {
    return instance.post('api/client', client).catch((err) => {
      console.log(err);
    });
  }
}

export default IdentityClientsProxy;
