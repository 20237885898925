<template>
  <div class="uploadBox">
    <div
      :class="borderStyle"
      @drop="onDrop"
      @dragenter="preventDefault"
      @dragover="preventDefault"
      @dragleave="preventDefault"
    >
      <div v-show="privateValue.length > 0" class="fileNames">
        <template v-for="file in privateValue" v-key="file.name">
          <p :key="file.name">
            <span class="fileItem">
              <div class="field is-grouped">
                <span style="padding-right: 15px">
                  <img style="width: 16px" src="../assets/xsd_icon.svg" />
                </span>
                <span style="padding-right: 24px">
                  <p>{{ file.name }}</p>
                </span>
                <span
                  :title="$t('DOWNLOAD')"
                  style="padding-right: 21px; cursor: pointer"
                  @click="onFileDownload(file)"
                >
                  <img style="width: 16px" src="../assets/download.svg" />
                </span>
                <span
                  :title="$t('REMOVE')"
                  style="cursor: pointer"
                  @click="removeFile(file)"
                >
                  <img style="width: 16px" src="../assets/cross.svg" />
                </span>
              </div>
            </span>
          </p>
        </template>
      </div>
      <div style="padding-top: 28px; padding-bottom: 28px">
        <div class="dragDropIcon" v-show="privateValue.length === 0">
          <img style="width: 44px" src="../assets/xsd_icon.svg" />
        </div>
        <p>{{ headerMessage }}</p>
        <p>&nbsp;</p>
        <div class="buttons is-marginless">
          <button @click="$refs.uploadUnput.click()" class="button is-primary">
            <span style="padding: 15px">{{ $t('UPLOAD') }}</span>
          </button>
        </div>
      </div>

      <input
        ref="uploadUnput"
        type="file"
        id="items"
        accept=".xsd"
        multiple
        @change="onChange"
      />
    </div>
    <div :class="modalCls">
      <div class="modal-background"></div>
      <div class="modal-card" :style="{ 'border-radius': '6px' }">
        <section class="modal-card-body">
          <h2 class="is-size-4">Заменить существующий файл новым?</h2>
          <p class="has-text-left">
            Файл с таким именем уже существует. Файлы не могут иметь одинаковые
            имена.
          </p>
          <div class="buttons is-right">
            <button @click="onAddXsdFiles" class="button is-primary">
              Заменить
            </button>
            <button @click="onCloseModal" class="button">Отмена</button>
          </div>
        </section>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="onCloseModal"
      ></button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'DragAndDrop',
    props: {
      headerMessage: {
        type: String,
        default() {
          return this.$t('DRAG_AND_DROP_HEADER_MESSAGE');
        },
      },
      value: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      borderStyle() {
        var style = 'dropArea';
        if (this.isHighlight) style += ' drop-highlight';
        return style;
      },
      modalCls() {
        return this.isModalActive ? 'modal is-active' : 'modal';
      },
    },
    data() {
      return {
        privateValue: this.value,
        isHighlight: false,
        isModalActive: false,
      };
    },
    watch: {
      value(newV) {
        this.privateValue = newV;
      },
    },
    methods: {
      getFileName(file) {
        return file.name.split('.').slice(0, -1).join('.');
      },
      handleFiles(tempFiles) {
        let files = [...tempFiles].filter(
          (x) => x.type === 'application/xml' || x.name.endsWith('.xsd')
        );
        var me = this;
        const alreadyHasFile = me.privateValue.some(
          (pv) =>
            files.filter((newFile) => me.getFileName(newFile) === pv.name)
              .length > 0
        );
        if (alreadyHasFile) {
          me.tempXsdFiles = files;
          me.isModalActive = true;
          return;
        }
        me.addNewXsdFiles(files);
      },
      onAddXsdFiles() {
        this.addNewXsdFiles(this.tempXsdFiles, true);
      },
      addNewXsdFiles(files, alreadyHasFile = false) {
        const me = this;
        if (!files.length) return;
        for (var i = 0; i < files.length; i++) {
          files[i].localUrl = window.URL.createObjectURL(files[i]);
          let reader = new FileReader();
          reader.file = files[i];
          reader.onload = function (event) {
            if (alreadyHasFile) {
              const locFile = me.privateValue.find(
                (file) => file.name == me.getFileName(event.target.file)
              );
              locFile.body = event.target.result;
            } else {
              me.privateValue.push({
                name: me.getFileName(reader.file),
                body: event.target.result,
                description: '',
              });
            }
            me.$emit('input', me.privateValue);
          };
          reader.readAsText(files[i]);
        }
        this.$refs.uploadUnput.value = '';
        this.onCloseModal();
      },
      onChange(e) {
        var files = e.target.files || e.dataTransfer.files;
        this.handleFiles(files);
        this.$refs.uploadUnput.value = '';
      },
      preventDefault(event) {
        if (event.type === 'dragenter' || event.type === 'dragover')
          this.isHighlight = true;
        if (event.type === 'dragleave') this.isHighlight = false;
        event.preventDefault();
        event.stopPropagation();
      },
      onDrop(e) {
        e.preventDefault();
        this.isHighlight = false;
        var files = e.target.files || e.dataTransfer.files;
        this.handleFiles(files);
      },
      removeFile(file) {
        this.privateValue.splice(this.privateValue.indexOf(file), 1);
      },
      onFileDownload(file) {
        var element = document.createElement('a');
        /* if (!file.localUrl) {
          file.localUrl = window.URL.createObjectURL(
            new Blob([file.body], { type: 'application/xsd' })
          );
        } */
        file.localUrl = window.URL.createObjectURL(
          new Blob([file.body], { type: 'application/xsd' })
        );
        element.setAttribute('href', file.localUrl);
        element.setAttribute('download', `${file.name}.xsd`);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      },
      onCloseModal() {
        this.isModalActive = false;
      },
    },
  };
</script>
<style lang="scss">
  .uploadBox {
    position: relative;
    margin-bottom: 15px;
  }

  .uploadBox p {
    text-align: center;
    color: #767676;
  }

  .uploadBox .fileNames {
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 24px;
    padding-left: 24px;
    padding-bottom: 10px;
  }

  .uploadBox .fileNames p {
    text-align: left;
  }

  /* Drag and drop */
  .uploadBox .dropArea {
    position: relative;
    width: 100%;
    min-height: 128px;
    background: #ffffff;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23DFE2E7' stroke-width='3' stroke-dasharray='10%2c 10' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
    border-radius: 4px;
    text-align: center;
    font-size: 1em;
  }

  .uploadBox .dropArea.drop-highlight {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%234ea500' stroke-width='3' stroke-dasharray='10%2c 10' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  }

  .uploadBox .dropArea .buttons {
    justify-content: center;
  }

  .uploadBox .dropArea input {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 1%;
    height: 1%;
    opacity: 0;
  }
  /* End drag and drop */
</style>
