<template>
  <div>
    <div class="columns">
      <div class="column is-9">
        <div class="field is-horizontal" style="margin-bottom:28px">
          <div class="field-label is-small has-text-left">
            <label class="label">{{ $t('CLIENT_RIGHTS_TABS_SCHEMA') }}</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <div class="select is-fullwidth">
                  <select :value="activeProject" @change="onChangeSelect">
                    <option
                      v-for="opt in projects"
                      :key="opt.projectAlias"
                      :value="opt.projectAlias"
                    >{{opt.name}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-body">
            <div class="field">
              <div class="control has-icons-left">
                <input
                  class="input"
                  type="text"
                  v-model="searchString"
                  :placeholder="$t('CLIENT_RIGHTS_TABS_PLACEHOLDER')"
                />
                <span class="icon is-small is-left">
                  <img :style="{ width: '15px'}" src="../assets/loupe.svg" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns" style="margin-bottom:28px;">
      <div class="column">
        <table class="table is-narrow">
          <thead>
            <tr>
              <th></th>
              <th>{{ $t('CLIENT_RIGHTS_TABS_TYPE_NAME') }}</th>
              <th>{{ $t('CLIENT_RIGHTS_TABS_TYPE_ALIAS') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="right in filteredRights" :key="`${right.typeAlias}_${activeProject}`">
              <td>
                <input
                  type="checkbox"
                  :value="right.isCheсked"
                  :checked="right.isCheсked"
                  @input="onInputChange(right)"
                  :id="right.typeAlias"
                />
                <label :for="right.typeAlias"></label>
              </td>
              <td>{{right.name}}</td>
              <td>{{right.typeAlias}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "RightTab",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters(["projects"]),
    filteredRights() {
      var res = this.privateValue.find(
        (x) => x.projectAlias === this.activeProject
      );
      if (this.searchString.length < 1) {
        return res !== undefined ? res.readingRightDtos : [];
      }
      return res.readingRightDtos.filter(
        (x) =>
          x.typeAlias.toLowerCase().includes(this.searchString.toLowerCase()) ||
          x.name.toLowerCase().includes(this.searchString.toLowerCase())
      );
    },
  },
  created() {},
  data() {
    return {
      searchString: "",
      activeProject: "all",
      projectsWithAll: [],
      privateValue: this.value,
    };
  },
  watch: {
    value(newV) {
      this.privateValue = newV;
    },
  },
  methods: {
    onChangeSelect(opt) {
      this.activeProject = opt.target.value;
    },
    onInputChange(right) {
      if (this.activeProject === "all") {
        this.privateValue.forEach((project) => {
          var valueRight = project.readingRightDtos.find(
            (x) => x.typeAlias === right.typeAlias
          );
          valueRight.isCheсked = !right.isCheсked;
        });
      } else {
        var res = this.privateValue.find(
          (x) => x.projectAlias === this.activeProject
        );
        var valueRight = res.readingRightDtos.find(
          (x) => x.typeAlias === right.typeAlias
        );
        valueRight.isCheсked = !right.isCheсked;
      }
      this.$emit("input", this.privateValue);
    },
  },
};
</script>

<style lang="scss" scoped>
table thead tr th:first-child {
  width: 40px;
  padding-right: 36px;
}

.select:not(.is-multiple):not(.is-loading)::after {
  border-color: #90a0b7;
}
</style>