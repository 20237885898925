<template>
  <div></div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "OidcCallback",
  methods: {
    ...mapActions("oidcStore", [
      "oidcSignInCallback",
      "signOutOidcCallback",
      "authenticateOidc",
    ]),
  },
  created() {
    this.oidcSignInCallback()
      .then((result) => {
        this.$router.push(result);
      })
      .catch((err) => {
        console.error(err);
      });
  },
};
</script>