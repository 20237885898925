export const mapDtoToClient = function (clientDto) {
  let client = {
    ...clientDto,
    redirectUri:
      clientDto.redirectUris?.length > 0 ? clientDto.redirectUris[0] : null,
      postLogoutRedirectUri:
      clientDto.postLogoutRedirectUris?.length > 0 ? clientDto.postLogoutRedirectUris[0] : null,
  };
  delete client.redirectUris;
  delete client.postLogoutRedirectUris;
  return client;
};

export const clientClientToDto = function (client) {
  let clientDto = {
    ...client,
    redirectUris: client.redirectUri?.length > 0 ? [client.redirectUri] : null,
    postLogoutRedirectUris: client.postLogoutRedirectUri?.length > 0 ? [client.postLogoutRedirectUri] : null,
  };
  delete client.redirectUris;
  delete client.postLogoutRedirectUris;
  return clientDto;
};
