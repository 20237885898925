export const grantTypes = {
  _values: [
    { id: 'client_credentials', name: 'Как внешняя система' },
    {
      id: 'authorization_code',
      name: 'Как пользователь Geometa (требуется логин и пароль)',
    },
  ],
  get ClientCredentials() {
    return 'client_credentials';
  },
  get AuthorizationCode() {
    return 'authorization_code';
  },

  *[Symbol.iterator]() {
    for (let val of this._values) {
      yield val;
    }
  },
};
