// i18n-setup.js
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import LocalesProxy from '../proxies/localesProxy.js';

Vue.use(VueI18n);

export const i18n = new VueI18n({});

export function loadLanguageAsync() {
  const ns = 'jasper-client';
  return LocalesProxy.getLocales()
    .then((locales) => {
      if (locales.current === locales.fallback) {
        return LocalesProxy.getTranslations(locales.current, ns).then(
          (translations) => {
            i18n.locale = locales.current;
            return [{ locale: locales.current, translations }];
          }
        );
      } else {
        return Promise.allSettled([
          LocalesProxy.getTranslations(locales.current, ns).then(
            (translations) => {
              i18n.locale = locales.current;
              return { locale: locales.current, translations };
            }
          ),
          LocalesProxy.getTranslations(locales.fallback, ns).then(
            (translations) => {
              i18n.fallbackLocale = locales.fallback;
              return { locale: locales.fallback, translations };
            }
          ),
        ]).then((results) => results.map((item) => item.value));
      }
    })
    .then((i18nSet) => {
      i18nSet.forEach((set) => {
        i18n.setLocaleMessage(set.locale, set.translations);
      });
    });
}
