import store from '@/store';
import axios from 'axios';

//axios.defaults.baseURL = window.location.origin + '/api';
axios.defaults.headers.get['Accepts'] = 'appliacation/json';
axios.defaults.headers.get['Content-Type'] =
  'application/json, text/plain, */*';

const instance = axios.create({
  baseURL: window.location.origin,
  headers: {
    'Content-Type': 'application/json, text/plain, */*',
  },
});
instance.CancelToken = axios.CancelToken;

instance.interceptors.request.use(function (config) {
  config.headers.common['Authorization'] =
    'Bearer ' + store.state.oidcStore.access_token;
  return config;
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    handleError(error);

    return Promise.reject(error);
  }
);

const handleError = function (error) {
  if (error.response) {
    if (error.response.status === 400) {
      alert(error.response.data.error);
      return { message: error.response.data.error };
    }
  }
};

export default instance;
