import Vue from 'vue';
import Vuex from 'vuex';
import { vuexOidcCreateStoreModule, vuexOidcCreateUserManager } from 'vuex-oidc';
import oidcSettings from './config/oidc';
import IdentityClientsProxy from './proxies/identityClientsProxy.js';
import AccountProxy from './proxies/accountProxy';
import { clientClientToDto, mapDtoToClient } from './utils/mapper';

Vue.use(Vuex);
let userManager = vuexOidcCreateUserManager(oidcSettings);

/* eslint no-param-reassign: ["error", { "props": false }] */
export default new Vuex.Store({
  modules: {
    oidcStore: vuexOidcCreateStoreModule(
      oidcSettings,
      // Optional OIDC store settings
      {
        namespaced: true,
        dispatchEventsOnWindow: true,
      },
      // Optional OIDC event listeners
      {
        userLoaded: function (user) {
          console.log('OIDC user is loaded:', user);
        },
        userUnloaded: () => console.log('OIDC user is unloaded'),
        accessTokenExpiring: () => console.log('Access token will expire'),
        accessTokenExpired: () => {
          window.console.log("Access token did expire");
          userManager.signoutRedirect();
        },
        silentRenewError: () => console.log('OIDC user is unloaded'),
        userSignedOut: (user) => {
          console.log('OIDC user is signed out');
          userManager.signoutRedirect({id_token_hint: user.id_token});
        },
      }
    ),
  },
  state: {
    clients: [],
    clientInfo: {},
    clientIsValid: true,
    projects: [],
    isClientInfoLoading: false,
    isSaving: false,
    userInfo: null
  },
  mutations: {
    setClients: (state, payload) => {
      state.clients = payload;
    },
    setClientInfo: (state, payload) => {
      if (payload.isNew) {
        IdentityClientsProxy.canselationTokens.forEach((x) => x.cancel());
        IdentityClientsProxy.canselationTokens = [];
      }
      state.clientInfo = payload;
    },
    setClientBody: (state, payload) => {
      state.clientInfo.xsdOperations = payload.xsdOperations;
      state.clientInfo.readingRights = payload.readingRights;
    },
    setProjects: (state, payload) => {
      state.projects = payload;
    },
    setIsClientInfoLoading: (state, payload) => {
      state.isClientInfoLoading = payload;
    },
    setIsSaving: (state, payload) => {
      state.isSaving = payload;
    },
    setUserInfo: (state, payload) => {
      state.userInfo = payload;
    },
  },
  actions: {
    GET_CLIENTS: ({ commit }) => {
      commit('setClients', []);
      return IdentityClientsProxy.GetAll()
        .then((response) => response.data.map(mapDtoToClient))
        .then((clients) => commit('setClients', clients));
    },
    GET_CLIENT: ({ commit }, payload) => {
      commit('setClientInfo', payload);
      commit('setIsClientInfoLoading', true);
      return IdentityClientsProxy.GetClientInfo(payload.login).then(
        (response) => {
          if (response) {
            commit('setClientBody', mapDtoToClient(response.data));
          }
          commit('setIsClientInfoLoading', false);
        }
      );
    },
    SAVE_CLIENT: ({ state, commit }) => {
      if (state.isSaving) return;
      commit('setIsSaving', true);

      if (state.clientInfo.key === 0)
        return IdentityClientsProxy.CreateClient(
          clientClientToDto(state.clientInfo)
        ).then((resp) => {
          const savedClient = mapDtoToClient(resp.data);
          state.clientInfo = savedClient;
          setTimeout(() => commit('setIsSaving', false), 1000);
          return savedClient;
        });
      return IdentityClientsProxy.SaveClient(
        clientClientToDto(state.clientInfo)
      ).then((resp) => {
        setTimeout(() => commit('setIsSaving', false), 1000);
        return resp.data;
      });
    },
    REMOVE_CLIENT: ({ state, commit }) => {
      return IdentityClientsProxy.RemoveClient(state.clientInfo.key).then(() =>
        commit('setClientInfo', {})
      );
    },
    SET_CLIENTINFO_STATE: ({ commit }, payload) => {
      commit('setClientInfo', payload);
    },
    GET_PROJECTS: ({ commit }) => {
      return AccountProxy.GetProjects().then((response) =>
        commit('setProjects', response.data)
      );
    },
    GET_USER_INFO: ({commit}) => {
      return AccountProxy
      .GetUserInfo().then(response => {
        commit('setUserInfo', response.data)
      })
    }
  },
  getters: {
    clients: (state) => {
      return state.clients;
    },
    clientInfo: (state) => {
      return state.clientInfo;
    },
    projects: (state) => {
      return state.projects;
    },
    isClientInfoLoading: (state) => {
      return state.isClientInfoLoading;
    },
    isSaving: (state) => {
      return state.isSaving;
    },
    isUserManager: (state) => {
      return state.userInfo?.isManager
    },
    isUserAdministrator: (state) => {
      return state.projects.length
    }
  },
});
