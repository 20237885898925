var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:_vm.cardCss},[_c('div',{staticClass:"card-content card-content-min"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-3"},[_c('p',{staticClass:"is-size-5 has-text-weight-medium text-color"},[_vm._v(" "+_vm._s(_vm.$t('CLIENT_RIGHTS_TITLE'))+" ")])]),_c('div',{staticClass:"column is-offset-8 is-1"},[_c('span',{staticClass:"is-pulled-right",on:{"click":_vm.onClickExpand}},[(_vm.isExpand)?_c('img',{style:({
                  width: '16px',
                  cursor: 'pointer',
                  'user-select': 'none',
                }),attrs:{"src":require("../assets/ExpandUp.svg")}}):_c('img',{staticClass:"rotate180",style:({
                  width: '16px',
                  cursor: 'pointer',
                  'user-select': 'none',
                }),attrs:{"src":require("../assets/ExpandUp.svg")}})])])]),_c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExpand),expression:"isExpand"}]},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"tabs"},[_c('ul',_vm._l((_vm.tabs),function(tab){return _c('li',{key:tab.index,class:tab.isActive ? 'is-active' : '',on:{"click":function($event){return _vm.onTabClick(tab)}}},[_c('a',[_vm._v(_vm._s(tab.name))])])}),0)])])]),(_vm.activeTab.index == 0)?_c('drag-and-drop',{model:{value:(_vm.value.xsdOperations),callback:function ($$v) {_vm.$set(_vm.value, "xsdOperations", $$v)},expression:"value.xsdOperations"}}):_vm._e(),_c('keep-alive',[(_vm.activeTab.index == 1)?_c('rights-tab',{model:{value:(_vm.value.readingRights),callback:function ($$v) {_vm.$set(_vm.value, "readingRights", $$v)},expression:"value.readingRights"}}):_vm._e()],1)],1)])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }