<template>
  <div class="navbar-end">
    <div class="navbar-item" v-click-outside="() => isPopupActive = false">
      <div @click="isPopupActive = !isPopupActive" class="circle">
        <img :style="{ width: '16px' }" src="../assets/signin.svg" />
      </div>
      <img
        @click="isPopupActive = !isPopupActive"
        :style="{ width: '16px', cursor: 'pointer', 'user-select': 'none' }"
        src="../assets/ExpandDown.svg"
      />
      <span v-show="isPopupActive" class="rectangle">
        <p class="is-size-6">{{ username }}</p>
        <hr class="hr-class" />
        <p @click="logOut" class="is-size-6">{{ $t('EXIT') }}</p>
      </span>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'AuthUser',
    computed: {
      ...mapGetters('oidcStore', ['oidcIsAuthenticated', 'oidcUser']),
      username() {
        return this.oidcUser ? this.oidcUser.name : 'войти';
      }
    },
    data() {
      return {
        isPopupActive: false,
        newPassword: '',
        oldPassword: '',
        hasErrorsOldPass: false,
        textErrorOldPass: '',
        hasErrorsNewPass: false,
        textErrorNewPass: '',
      };
    },
    methods: {
      ...mapActions('oidcStore', ['signOutOidc']),
      logOut() {
        this.signOutOidc(null, {id_token_hint: this.oidcUser.id_token, post_logout_redirect_uri: this.oidcUser.post_logout_redirect_uri}).then(() => {
          //AccountProxy.LogOut();
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .circle {
    width: 40px;
    height: 40px;
    background: #ff776f;
    border-radius: 50%;
    margin-right: 9px;
    cursor: pointer;
    user-select: none;
    img {
      margin-top: 12px;
      margin-left: 12px;
      cursor: pointer;
      user-select: none;
    }
  }

  .triangle-up {
    width: 0;
    position: absolute;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #fff;
    margin-top: 40px;
    right: 52px;
    border-radius: 3px;
  }

  .rectangle {
    position: absolute;
    width: 152px;
    height: auto;
    padding: 5px;
    background: #ffffff;
    right: 13px;
    top: 100%;
    border-radius: 4px;
    box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.06);
    p {
      padding-left: 0.75em;
      padding-bottom: 0.5em;
    }
    p:first-child {
      padding-top: 0.75em;
    }
    p:not(:first-child) {
      color: #9196a3;
      cursor: pointer;
      user-select: none;
    }
    .hr-class {
      margin-left: 1.5em;
      margin-bottom: 0.75em;
      margin-right: 1.5em;
      margin-top: 0em;
    }
  }

  .modal-card {
    width: 480px;
    border-radius: 6px;
  }
</style>
