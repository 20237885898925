import instance from '../services/api.js';

class LocalesProxy {
  static getCurrentLocale() {
    return instance
      .get(`api/locales/current`)
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        console.error(e);
      });
  }

  static getLocales() {
    return instance
      .get(`api/locales`)
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        console.error(e);
      });
  }

  static getTranslations(locale, ns) {
    return instance
      .get(`api/localization/${locale}/${ns}`)
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        console.error(e);
      });
  }
}

export default LocalesProxy;
