<template>
  <div id="app">
    <div :class="isLoading">
      <div class="loader is-loading"></div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "App",
  computed: {
    ...mapGetters("oidcStore", ["oidcIsAuthenticated"]),
    isLoading() {
      return this.oidcIsAuthenticated
        ? "loader-wrapper"
        : "loader-wrapper is-active";
    },
  },
};
</script>

<style lang="scss">
@import "./assets/main.scss";
.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;

  .loader {
    height: 80px;
    width: 80px;
    border: 2px solid #ff776f;
    border-right-color: transparent;
    border-top-color: transparent;
  }

  &.is-active {
    opacity: 1;
    z-index: 1;
  }
}
</style>
