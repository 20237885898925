export const clickOutside = {
  bind: function (el, binding) {
    el.clickOutside = (e) => {
      if (e.target instanceof Element) {
        if (!el.contains(e.target) && el !== e.target) {
          binding.value(e);
        }
      }
    };
    document.addEventListener('click', el.clickOutside)
  },
  unbind: function (el) {
    document.removeEventListener('click', el.clickOutside)
  },
};