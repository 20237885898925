<template>
  <div class="margin-top-40">
    <button
      class="button btn-save-cls is-primary"
      @click="onSave"
      :disabled="isSaveBtnDisabled || isDisabled"
    >
      <img class="width" src="../assets/save.svg" />
      <span>{{ btnText }}</span>
    </button>
    <div class="card client-card">
      <header class="card-header"></header>
      <div class="card-content">
        <div class="content">
          <span class="columns" v-for="client in clients" :key="client.key">
            <span
              :title="client.name"
              class="column client-item"
              @click="onClickClient(client)"
            >
              <img
                v-if="client.key === activeClientKey"
                class="width"
                src="../assets/profile-active.svg"
              />
              <img v-else class="width" src="../assets/profile.svg" />
              <span
                :class="
                  client.key === activeClientKey
                    ? 'has-text-primary text-vert-align'
                    : 'text-vert-align'
                "
                >{{ client.name }}</span
              >
            </span>
          </span>
          <div v-show="isUserManager">
            <hr v-if="clients.length > 0" class="hr-class" />
            <span class="columns">
              <span class="column client-new-item" @click="onNewClientClick">
              <img class="width" src="../assets/plus.svg" />
              <span class="text-vert-align">{{
                $t('CLIENT_CARD_ADD_NEW_CLIENT')
              }}</span>
            </span>
          </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import { grantTypes } from '../utils/constants';
  import { canSave } from '../utils/clientFieldsChecker';

  export default {
    name: 'ClientCard',
    props: {
      clients: {
        type: Array,
        default: () => [],
        required: true,
      },
      value: {
        type: Object,
      },
    },
    computed: {
      ...mapGetters([
        'projects',
        'clientInfo',
        'isSaving',
        'isClientInfoLoading',
        'isUserManager',
        'isUserAdministrator'
      ]),
      isDisabled() {
          var predicate = this.isUserAdministrator || this.isUserManager
          return !predicate
      },
      activeClientKey() {
        return this.value === null || this.value === undefined
          ? -1
          : this.value.key;
      },
      isSaveBtnDisabled() {
        if (this.isSaving || this.isClientInfoLoading) return true;
        if(!canSave(this.clientInfo))
            return true;
        return false;
      },
    },
    watch: {
      isSaving(newV) {
        this.btnText = newV ? this.$t('SAVING') : this.$t('SAVE_CHANGES');
      },
    },
    data() {
      return {
        btnText: this.$t('SAVE_CHANGES'),
      };
    },
    methods: {
      onClickClient(client) {
        this.$emit('input', client);
      },
      onNewClientClick() {
        var newClient = {
          key: 0,
          name: '',
          login: '',
          password: '',
          description: '',
          isNew: true,
          xsdOperations: [],
          readingRights: JSON.parse(JSON.stringify(this.projects)),
          authType: grantTypes.ClientCredentials,
        };
        this.$emit('input', newClient);
      },
      onSave() {
        this.$emit('save');
      },
    },
  };
</script>
<style lang="scss" scoped>
  .margin-top-40 {
    margin-top: 40px;
  }
  .btn-save-cls {
    width: 280px;
    position: fixed;
  }
  .client-card {
    margin-top: 56px;
    position: fixed;
    width: 280px;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    .card-content {
      padding: 0.75rem;
    }
    .client-item {
      letter-spacing: 0.04em;
      cursor: pointer;
      color: #9196a3;
      justify-content: center;
      padding-left: 1em;
      padding-right: 1em;
      text-align: left;
      white-space: nowrap;
      user-select: none;
      text-overflow: ellipsis;
      width: 280px;
      overflow: hidden;
      .width {
        width: 16px;
        margin-right: 12px;
      }
    }
    .client-item:hover {
      background: #fff4f4;
    }
    .client-new-item {
      letter-spacing: 0.04em;
      cursor: pointer;
      color: #9196a3;
      justify-content: center;
      padding-left: 1em;
      padding-right: 1em;
      text-align: left;
      white-space: nowrap;
      user-select: none;
    }
    .client-new-item:hover {
      background: #fff4f4;
    }
    .hr-class {
      margin-left: 1.5em;
      margin-bottom: 0.75em;
      margin-right: 1.5em;
      margin-top: -0.75em;
    }
    .text-vert-align {
      vertical-align: text-bottom;
    }
  }
  .width {
    width: 16px;
    margin-right: 12px;
  }
</style>
