<template>
  <div>
    <h1>{{ $t('AUTHENTICATION_ERROR') }}</h1> 
  </div>
</template>
<script>
export default {
  name: 'OidcCallbackError',
};
</script>
