import instance from '../services/api.js';

class AccountProxy {
  static LogOut() {
    return instance.post('/Account/Logout?url=/account/login', null, { withCredentials: true }).catch((err) => {
      console.log(err);
    });
  }
  
  static GetUserInfo() {
    return instance.get('/api/user')
  }

  static GetProjects() {
    return instance.get('api/user/projects').catch((err) => {
      console.log(err);
    })
  }
}

export default AccountProxy;
