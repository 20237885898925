import { grantTypes } from "./constants";

const clientRules = {
    [grantTypes.ClientCredentials]: (client) => {
    if (!(Object.keys(client).length === 0 && client.constructor === Object)) {
      if (
        (!client.password || client.password.length < 1)
        || (!client.login || client.login.length < 1)
        || (!client.name || client.name.length < 1)
      ) {
        return false;
      }
    }
    return true;
  },
  [grantTypes.AuthorizationCode]: (client) => {
    if (!(Object.keys(client).length === 0 && client.constructor === Object)) {
      if (
        (!client.password || client.password.length < 1)
        || (!client.login || client.login.length < 1)
        || (!client.name || client.name.length < 1)
        || (!client.redirectUri || client.redirectUri.length < 1)
        || (!client.postLogoutRedirectUri || client.postLogoutRedirectUri.length < 1)
      ) {
        return false;
      }
    }
    return true;
  }
}

export function canSave(client) {
  if (client.authType) {
    return clientRules[client.authType](client);
  }
  return false;
}