<template>
  <div>
    <div :class="cardCss">
      <div class="card-content card-content-min">
        <div class="content">
          <div class="columns">
            <div class="column is-3">
              <p class="is-size-5 has-text-weight-medium text-color">
                {{ $t('CLIENT_RIGHTS_TITLE') }}
              </p>
            </div>
            <div class="column is-offset-8 is-1">
              <span class="is-pulled-right" @click="onClickExpand">
                <img
                  :style="{
                    width: '16px',
                    cursor: 'pointer',
                    'user-select': 'none',
                  }"
                  v-if="isExpand"
                  src="../assets/ExpandUp.svg"
                />
                <img
                  :style="{
                    width: '16px',
                    cursor: 'pointer',
                    'user-select': 'none',
                  }"
                  v-else
                  class="rotate180"
                  src="../assets/ExpandUp.svg"
                />
              </span>
            </div>
          </div>
          <transition name="fade">
            <div v-show="isExpand">
              <div class="columns">
                <div class="column">
                  <div class="tabs">
                    <ul>
                      <li
                        @click="onTabClick(tab)"
                        v-for="tab in tabs"
                        :key="tab.index"
                        :class="tab.isActive ? 'is-active' : ''"
                      >
                        <a>{{ tab.name }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <drag-and-drop
                v-model="value.xsdOperations"
                v-if="activeTab.index == 0"
              ></drag-and-drop>
              <keep-alive>
                <rights-tab
                  v-model="value.readingRights"
                  v-if="activeTab.index == 1"
                ></rights-tab>
              </keep-alive>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import dragAndDrop from './DragAndDrop.vue';
  import rightsTab from './RightsTab.vue';

  export default {
    name: 'ClientRights',
    components: {
      dragAndDrop,
      rightsTab,
    },
    props: {
      value: {
        type: Object,
        default: () => {},
        required: true,
      },
    },
    computed: {
      ...mapGetters(['isClientInfoLoading', 'projects']),
      activeTab() {
        return this.tabs.find((x) => x.isActive);
      },
      cardCss() {
        return this.isClientInfoLoading
          ? 'card client-info is-loading'
          : 'card client-info';
      }
    },
    data() {
      return {
        isExpand: true,
        tabs: [
          {
            name: this.$t('CLIENT_RIGHTS_TABS_CREATION'),
            index: 0,
            isActive: true,
          },
          {
            name: this.$t('CLIENT_RIGHTS_TABS_VIEW'),
            index: 1,
            isActive: false,
          },
        ],
      };
    },
    methods: {
      onClickExpand() {
        this.isExpand = !this.isExpand;
      },
      onTabClick(tab) {
        this.tabs.forEach((tab) => (tab.isActive = false));
        tab.isActive = true;
      },
    },
  };
</script>

<style lang="scss" scoped>
  img.rotate180 {
    transform: rotate(180deg);
  }
  .client-info {
    margin-left: 308px;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    margin-top: 16px;
  }
  .card-content-min {
    padding-bottom: 0.1rem;
  }

  .field-label {
    .label {
      line-height: 30px;
    }
  }

  .field:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  .control input:focus,
  textarea:focus,
  textarea:active {
    border-color: #465362;
    box-shadow: 0 0 0 0;
  }

  .text-color {
    color: #465362;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.1s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
    padding-bottom: 0.1rem;
  }
  .tabs li {
    padding-right: 36px;
  }
  .content ul {
    margin-left: 0em;
  }
  .tabs a {
    padding: 0px;
  }

  .card {
    &.is-loading {
      position: relative;
      pointer-events: none;
      opacity: 0.5;
      &:after {
        position: absolute;
        top: calc(50% - 2.5em);
        left: calc(50% - 2.5em);
        width: 5em;
        height: 5em;
        border-width: 0.25em;
      }
    }
  }
</style>
