import Vue from 'vue';
import App from './App.vue';
import { i18n, loadLanguageAsync } from './localization/i18n-setup';
import router from './router';
import store from './store';
import { clickOutside } from "@/directives/clickOutside.ts";


Vue.directive('clickOutside', clickOutside);

loadLanguageAsync().then(() => {
    new Vue({
        i18n,
        router,
        store,
        render: (h) => h(App),
    }).$mount('#app');
});
