import Vue from 'vue';
import Router from 'vue-router';
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc';
import store from './store';
import home from './components/Home.vue';
import OidcCallback from './components/oidc/OidcCallback.vue';
import OidcCallbackError from './components/oidc/OidcCallbackError.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: home,
    },
    {
      path: '/oidc-callback', // Needs to match redirect_uri in you oidcSettings
      name: 'oidcCallback',
      component: OidcCallback,
      meta: {
        isOidcCallback: true,
        isPublic: true,
      },
    },
    {
      path: '/oidc-callback-error', // Needs to match redirect_uri in you oidcSettings
      name: 'oidcCallbackError',
      component: OidcCallbackError,
      meta: {
        isPublic: true,
      },
    },
  ],
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidcStore'));

export default router;
