<template>
  <div v-if="hasAccess" id="home" class="main-cls">
    <nav
      class="navbar navbar-instanse is-fixed-top"
      role="navigation"
      aria-label="main navigation"
    >
      <div class="navbar-container">
        <div class="navbar-brand">
          <a class="navbar-item" @click="discard">
            <img src="../assets/Logo.svg" width="112" height="28" />
          </a>
        </div>
        <auth-user></auth-user>
      </div>
    </nav>
    <div class="container">
      <clients-card
        @save="onSaveClient"
        :clients="clients"
        v-model="activeClient"
      ></clients-card>
      <div v-if="!isEmpty(clientInfo) && activeClient !== null">
        <client-info
          @removeClient="isModalActive = true"
          v-model="clientInfo"
        ></client-info>
        <client-rights v-show="isUserAdministrator" v-model="clientInfo"></client-rights>
      </div>
      <div v-else class="mainPageImg">
        <p class="is-size-4">{{ $t('CLIENT_NO_SELECTED_MESSAGE') }}</p>
        <img src="../assets/mainPage.svg" width="800" height="600" />
      </div>
    </div>

    <div :class="modalCls">
      <div class="modal-background"></div>
      <div class="modal-card" :style="{ 'border-radius': '6px' }">
        <section class="modal-card-body">
          <p>{{ $t('CLIENT_REMOVING_CONFIRMING_MESSAGE') }}</p>
          <div class="buttons is-right">
            <button @click="onRemoveClient" class="button is-primary">
              {{ $t('YES') }}
            </button>
            <button @click="onCloseModal" class="button">{{ $t('NO') }}</button>
          </div>
        </section>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="onCloseModal"
      ></button>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import AuthUser from './AuthUser';
  import ClientInfo from './ClientInfo.vue';
  import ClientRights from './ClientRights.vue';
  import ClientsCard from './ClientsCard.vue';

  export default {
    name: 'Home',
    components: {
      ClientsCard,
      ClientInfo,
      ClientRights,
      AuthUser,
    },
    data() {
      return {
        activeClient: null,
        isModalActive: false,
      };
    },
    computed: {
      ...mapGetters('oidcStore', ['oidcIsAuthenticated']),
      ...mapGetters(['clients', 'clientInfo', 'isUserAdministrator']),
      hasAccess() {
        return this.oidcIsAuthenticated || this.$route.meta.isPublic;
      },
      modalCls() {
        return this.isModalActive ? 'modal is-active' : 'modal';
      },
    },
    created() {
      this.$store.dispatch('GET_CLIENTS');
      this.$store.dispatch('GET_PROJECTS');
      this.$store.dispatch('GET_USER_INFO');
    },
    watch: {
      activeClient(newV) {
        if (newV !== null && newV.key !== 0) {
          this.$store.dispatch('GET_CLIENT', newV);
        } else if (newV !== null && newV.key === 0) {
          this.$store.dispatch('SET_CLIENTINFO_STATE', newV);
        }
      },
    },
    methods: {
      discard() {
        this.activeClient = null;
        this.$store.dispatch('SET_CLIENTINFO_STATE', {});
      },
      isEmpty(obj) {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
      },
      onSaveClient() {
        if (!this.isEmpty(this.clientInfo))
          this.$store.dispatch('SAVE_CLIENT').then(() => {
            this.$store.dispatch('GET_CLIENTS');
          });
      },
      onRemoveClient() {
        this.isModalActive = false;
        if (!this.isEmpty(this.clientInfo))
          this.$store
            .dispatch('REMOVE_CLIENT')
            .then(() => this.$store.dispatch('GET_CLIENTS'));
      },
      onCloseModal() {
        this.isModalActive = false;
      },
    },
  };
</script>

<style lang="scss">
  .navbar-instanse {
    height: 92px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
  }

  .main-cls {
    letter-spacing: 0.04em;
    margin-top: 132px;
  }

  .mainPageImg img {
    position: relative;
    left: 360px;
    top: 140px;
  }

  .mainPageImg p {
    left: 470px;
    top: 90px;
    color: #9196a3;
    position: relative;
    line-height: 30px;
  }

  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .navbar-end {
    margin: 0;
  }

  .navbar-item {
    display: flex;
  }

  @media screen and (min-width: 1824px) {
    .navbar-container:not(.is-max-desktop):not(.is-max-widescreen) {
      max-width: 1344px;
    }
  }

  @media screen and (min-width: 1632px) {
    .navbar-container:not(.is-max-desktop) {
      max-width: 1152px;
    }
  }

  @media screen and (min-width: 1440px) {
    .navbar-container {
      max-width: 960px;
      margin: 0 auto;
    }
  }
</style>
