<template>
  <div>
    <div class="buttons is-right">
      <button
        @click="onDeleteBtnClick"
        v-show="isUserManager"
        class="button client-remove-btn is-marginless"
        :title="$t('CLIENT_INFO_REMOVE')"
        :disabled="value.isNew"
      >
        <img :style="{ width: '16px' }" src="../assets/Delete.svg" />
      </button>
    </div>
    <div class="card client-info">
      <div class="card-content card-content-min">
        <div class="content">
          <div class="columns">
            <div class="column is-3">
              <p class="is-size-5 has-text-weight-medium text-color">
                {{ $t('CLIENT_INFO_TITLE') }}
              </p>
            </div>
            <div class="column is-offset-8 is-1">
              <span class="is-pulled-right" @click="onClickExpand">
                <img
                  :style="{
                    width: '16px',
                    cursor: 'pointer',
                    'user-select': 'none',
                  }"
                  v-if="isExpand"
                  src="../assets/ExpandUp.svg"
                />
                <img
                  :style="{
                    width: '16px',
                    cursor: 'pointer',
                    'user-select': 'none',
                  }"
                  v-else
                  class="rotate180"
                  src="../assets/ExpandUp.svg"
                />
              </span>
            </div>
          </div>
          <transition name="fade">
            <div v-show="isExpand">
              <div class="columns">
                <div class="column is-9">
                  <div class="field is-horizontal">
                    <div class="field-label is-small has-text-left">
                      <label class="label">
                        {{ $t('CLIENT_INFO_NAME') }}<nobr/>
                        <span class="has-text-primary">*</span>
                      </label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <div class="control">
                          <input
                            class="input"
                            type="text"
                            v-model="value.name"
                            maxlength="100"
                            :disabled="!isUserManager"
                          />
                        </div>
                        <p v-show="nameFieldError" class="help is-danger">
                          {{ $t('CLIENT_INFO_REQUIRED_FIELD_MESSAGE') }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-small has-text-left">
                      <label class="label">
                        {{ $t('CLIENT_INFO_AUTHTYPE') }}<nobr/>
                        <span class="has-text-primary">*</span>
                      </label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <div class="control">
                          <div class="select fix-select-width">
                            <select
                              v-model="value.authType"
                              @change="onAuthTypeChange"
                              :disabled="!isUserManager"
                            >
                              <option
                                :value="id"
                                v-for="{ id, name } in authTypes"
                                :key="id"
                              >
                                {{ name }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-small has-text-left">
                      <label class="label">
                        {{ $t('CLIENT_INFO_CLIENTID') }}<nobr/>
                        <span class="has-text-primary">*</span>
                      </label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <div class="control">
                          <input
                            class="input"
                            type="text"
                            v-model="value.login"
                            :disabled="isOldClient || !isUserManager"
                            maxlength="20"
                            autocomplete="off"
                          />
                        </div>
                        <p v-show="loginFieldError" class="help is-danger">
                          {{ $t('CLIENT_INFO_REQUIRED_FIELD_MESSAGE') }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div v-show="isUserManager" class="field is-horizontal">
                    <div class="field-label is-small has-text-left">
                      <label class="label">
                        {{ $t('CLIENT_INFO_CLIENTSECRET') }}<nobr/>
                        <span class="has-text-primary">*</span>
                      </label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <div class="control">
                          <input
                            class="input"
                            type="password"
                            v-model="value.password"
                            autocomplete="off"
                            maxlength="20"
                          />
                        </div>
                        <p v-show="passFieldError" class="help is-danger">
                          {{ $t('CLIENT_INFO_REQUIRED_FIELD_MESSAGE') }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    class="field is-horizontal"
                    v-if="value.authType === authTypes.AuthorizationCode"
                  >
                    <div class="field-label is-small has-text-left">
                      <label class="label">
                        {{ $t('CLIENT_INFO_REDIRECTURI') }}<nobr/>
                        <span class="has-text-primary">*</span>
                      </label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <div class="control">
                          <input
                            class="input"
                            type="text"
                            v-model="value.redirectUri"
                            :disabled="!isUserManager"
                          />
                        </div>
                        <p
                          v-show="redirectUriFieldError"
                          class="help is-danger"
                        >
                          {{ $t('CLIENT_INFO_REQUIRED_FIELD_MESSAGE') }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    class="field is-horizontal"
                    v-if="value.authType === authTypes.AuthorizationCode"
                  >
                    <div class="field-label is-small has-text-left">
                      <label class="label">
                        {{ $t('CLIENT_INFO_POSTLOGOUTREDIRECTURI') }}<nobr/>
                        <span class="has-text-primary">*</span>
                      </label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <div class="control">
                          <input
                            class="input"
                            type="text"
                            v-model="value.postLogoutRedirectUri"
                            :disabled="!isUserManager"
                          />
                        </div>
                        <p
                          v-show="postLogoutRedirectUriFieldError"
                          class="help is-danger"
                        >
                          {{ $t('CLIENT_INFO_REQUIRED_FIELD_MESSAGE') }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    class="field is-horizontal"
                    :style="{ 'padding-bottom': '1.5rem' }"
                  >
                    <div class="field-label is-small has-text-left">
                      <label class="label">{{
                        $t('CLIENT_INFO_DESCRIPTION')
                      }}</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <div class="control">
                          <textarea
                            class="textarea"
                            type="text"
                            rows="2"
                            v-model="value.description"
                            :disabled="!isUserManager"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { grantTypes } from '../utils/constants';
  export default {
    name: 'ClientInfo',
    props: {
      value: {
        type: Object,
        default: () => {},
        required: true,
      },
    },
    data() {
      return {
        isExpand: true,
      };
    },
    computed: {
      ...mapGetters([
        'isUserManager'
      ]),
      isOldClient() {
        return !this.value.isNew
      },
      passFieldError() {
        if (this.value.password.length < 1) return true;
        return false;
      },
      loginFieldError() {
        if (this.value.login.length < 1) return true;
        return false;
      },
      nameFieldError() {
        if (this.value.name.length < 1) return true;
        return false;
      },
      redirectUriFieldError() {
        if (!this.value.redirectUri) return true;
        return false;
      },
      postLogoutRedirectUriFieldError() {
        if (!this.value.postLogoutRedirectUri) return true;
        return false;
      },

      authTypes() {
        return grantTypes;
      },
    },
    methods: {
      onClickExpand() {
        this.isExpand = !this.isExpand;
      },
      onDeleteBtnClick() {
        this.$emit('removeClient');
      },
      onAuthTypeChange(event) {
        if (event.target.value === this.authTypes.ClientCredentials) {
          this.value.redirectUri = null;
          this.value.postLogoutRedirectUri = null;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  img.rotate180 {
    transform: rotate(180deg);
  }

  .client-info {
    margin-left: 308px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    margin-top: 16px;
  }

  .client-remove-btn {
    border: 1px solid #dfe2e7;
    background: #ffffff;
  }

  .client-remove-btn:focus,
  .client-remove-btn:active,
  .client-remove-btn:focus:not(:active) {
    box-shadow: 0 0 0 0;
    border-color: #ff776f;
  }

  .card-content-min {
    padding-bottom: 0.1rem;
  }

  .field:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  .text-color {
    color: #465362;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.1s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
    padding-bottom: 0.1rem;
  }

  .fix-select-width,
  .fix-select-width select {
    width: 100%;
  }

  .label {
    padding-top: 8px;
    line-height: 16px;
    min-width: 110px;
  }
</style>
